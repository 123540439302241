<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Educacion',

    metaInfo: { title: 'Educación Continua' },
    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        //'ConvocatoriaCertificacion',
        'ConvocatoriaRecertificacion',
        //'CitatorioAsamblea',
        'info-educacion-continua',
        'info-alt',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'pricing-plans',
      },
    },
  }
</script>
